export enum EPlatformName {
  SAMSARA = "SAMSARA",
  WIALON = "WIALON",
  GEOTAB = "GEOTAB",
  MOTIVE = "MOTIVE",
  AZUGA = "AZUGA",
  AZUGA_ELD = "AZUGA_ELD",
  WORKWAVE = "WORKWAVE",
  TRIMBLE = "TRIMBLE",
  WEBFLEET = "WEBFLEET",
  VERIZON = "VERIZON",
  GPS_INSIGHT = "GPS_INSIGHT",
  MIX_TELEMATICS = "MIX_TELEMATICS",
  EROAD = "EROAD",
  FLEET_COMPLETE = "FLEET_COMPLETE",
  GPS_TAB = "GPS_TAB",
  LION_EIGHT = "LION_EIGHT",
  LYTX = "LYTX",
  OMNITRACS = "OMNITRACS",
  ISAAC = "ISAAC",
  NETRADYNE = "NETRADYNE",
  NEXTRAQ = "NEXTRAQ",
  ONE_STEP_GPS = "ONE_STEP_GPS",
  FLEETLOCATE_SPIREON_NSPIRE = "FLEETLOCATE_SPIREON_NSPIRE",
  XRS = "XRS",
  ZONAR = "ZONAR",
  ORBCOMM_ELD = "ORBCOMM_ELD",
  ORBCOMM_BLUE_TREE = "ORBCOMM_BLUE_TREE",
  VISION_TRACK = "VISION_TRACK",
  WAYLENS = "WAYLENS",
  INSIGHT_MOBILE_DATA = "INSIGHT_MOBILE_DATA",
  MONARCH_GPS = "MONARCH_GPS",
  RHINO = "RHINO",
  TOUCHTEL_GPS = "TOUCHTEL_GPS",
  TRUCKSPY = "TRUCKSPY",
  GPS_COMMANDER = "GPS_COMMANDER",
  ATNT_FLEET_COMPLETE = "ATNT_FLEET_COMPLETE",
  FLEET_UP = "FLEET_UP",
  ACTSOFT_ENCORE = "ACTSOFT_ENCORE",
  ADVANTAGE_ASSET_TRACKING = "ADVANTAGE_ASSET_TRACKING",
  ARGOS_CONNECTED_SOLUTIONS = "ARGOS_CONNECTED_SOLUTIONS",
  ARI_FLEET = "ARI_FLEET",
  ASSURED_TELEMATICS = "ASSURED_TELEMATICS",
  ATNT_FLEET = "ATNT_FLEET",
  ATTRIX = "ATTRIX",
  BADGER_FLEET_SOLUTIONS = "BADGER_FLEET_SOLUTIONS",
  BAR_CODING_CANADA = "BAR_CODING_CANADA",
  BLUEARROW = "BLUEARROW",
  CARRIERHQ = "CARRIERHQ",
  ENVUE_TELEMATICS = "ENVUE_TELEMATICS",
  FLEET_NAV_SYSTEMS = "FLEET_NAV_SYSTEMS",
  FLEET_PROFIT_CENTER = "FLEET_PROFIT_CENTER",
  FLEETBOSS_GPS = "FLEETBOSS_GPS",
  FLEETHOSTER = "FLEETHOSTER",
  FLEETISTICS = "FLEETISTICS",
  FLEETLOCATE_GEOTAB = "FLEETLOCATE_GEOTAB",
  FLEETMASTER = "FLEETMASTER",
  FLEXPORT = "FLEXPORT",
  GEOFORCE = "GEOFORCE",
  GLOSTONE = "GLOSTONE",
  GOFLEET = "GOFLEET",
  GOGPS = "GOGPS",
  GPS_FLEET_FINDER = "GPS_FLEET_FINDER",
  GPS_SOLUTIONS = "GPS_SOLUTIONS",
  GPS_TRACKING_CANADA = "GPS_TRACKING_CANADA",
  GRAYBOX_SOLUTIONS = "GRAYBOX_SOLUTIONS",
  GRIDLINE = "GRIDLINE",
  HIGHPOINT_GPS = "HIGHPOINT_GPS",
  IOTAB = "IOTAB",
  LYNX = "LYNX",
  ONTRAK_SOLUTIONS = "ONTRAK_SOLUTIONS",
  PENSKE = "PENSKE",
  PREPASS_ELD = "PREPASS_ELD",
  QUALITY_GPS = "QUALITY_GPS",
  RMJ_TECHNOLOGIES = "RMJ_TECHNOLOGIES",
  RUSH_ENTERPRISES = "RUSH_ENTERPRISES",
  RYDER = "RYDER",
  SAFETY_VISION = "SAFETY_VISION",
  SHELL_TELEMATICS = "SHELL_TELEMATICS",
  SURECAM = "SURECAM",
  T_MOBILE = "T_MOBILE",
  TRANSFLO = "TRANSFLO",
  TRAXXISGPS = "TRAXXISGPS",
  TRUPATH_SYSTEMS = "TRUPATH_SYSTEMS",
  VERTRAX = "VERTRAX",
  ZENDUIT = "ZENDUIT",
  HOLMAN = "HOLMAN",
  ENTERPRISE_FLEET_MANAGEMENT = "ENTERPRISE_FLEET_MANAGEMENT",
  LINXUP = "LINXUP",
  FLEETSHARP = "FLEETSHARP",
  ELD_RIDER_PRO = "ELD_RIDER_PRO",
  SHELD = "SHELD",
  XELD = "XELD",
  ZELD = "ZELD",
  REQUEST = "REQUEST",
  TELETRAC_NAVMAN = "TELETRAC_NAVMAN",
  GORILLA_SAFETY = "GORILLA_SAFETY",
  CLUTCH_ELD = "CLUTCH_ELD",
  CYNTRX_ELD_PLUS = "CYNTRX_ELD_PLUS",
  ELD_MANDATE_PRO = "ELD_MANDATE_PRO",
  // FLEET_LOCATE_ELD = "FLEET_LOCATE_ELD",
  PATRIOT = "PATRIOT",
  SIMPLEX_ELD = "SIMPLEX_ELD",
  TRACK_ON_ELD = "TRACK_ON_ELD",
  TRUCKER_PATH_ELD_PRO = "TRUCKER_PATH_ELD_PRO",
}

import {
  EAuthTypes,
  EPlatformName,
  ETelematicsMethodType,
  IPlatform,
} from "../types";

export const PLATFORMS: IPlatform[] = [
  {
    key: "35dd54bb-394c-4f3c-a5c4-fae5c3d885f9",
    const: EPlatformName.WIALON,
    value: "35dd54bb-394c-4f3c-a5c4-fae5c3d885f9",
    label: "Wialon",
    sp_id: "10",
    methods: [
      {
        id: "e05872fe-f283-4936-8f72-39a62ae2cb63",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://wialon.com",
        fixed_url: null,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "35dd54bb-394c-4f3c-a5c4-fae5c3d885f9",
    const: EPlatformName.GPS_COMMANDER,
    value: "35dd54bb-394c-4f3c-a5c4-fae5c3d885f9",
    label: "GPS Commander (FQ Wireless)",
    sp_id: "122",
    methods: [
      {
        id: "e05872fe-f283-4936-8f72-39a62ae2cb63",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://gpscommander.com",
        fixed_url: null,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl1.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "5cf5fc28-d12c-4248-afb5-0bd7526cb203",
    const: EPlatformName.SAMSARA,
    value: "5cf5fc28-d12c-4248-afb5-0bd7526cb203",
    label: "Samsara",
    sp_id: "1",
    methods: [
      {
        id: "4c6e2f2c-88d8-4beb-9b06-dbbfa23bb7bd",
        authorityType: EAuthTypes.OAUTH2,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.samsara.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "4c6e2f2c-88d8-4beb-9b06-dbbfa23bb7bd",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.samsara.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.GEOTAB,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Geotab",
    sp_id: "2",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.ACTSOFT_ENCORE,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "Actsoft Encore",
  //   sp_id: "38",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ADVANTAGE_ASSET_TRACKING,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Advantage Asset Tracking",
    sp_id: "67",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ARGOS_CONNECTED_SOLUTIONS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Argos Connected Solutions",
    sp_id: "148",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.ARI_FLEET,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "ARI Fleet",
  //   sp_id: "86",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ASSURED_TELEMATICS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Assured Telematics (GEOTAB)",
    sp_id: "78",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ATNT_FLEET,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "AT&T Fleet",
    sp_id: "138",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ATTRIX,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "AttriX",
    sp_id: "92",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.BADGER_FLEET_SOLUTIONS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Badger Fleet Solutions",
    sp_id: "76",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.BAR_CODING_CANADA,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "BarCoding Canada",
    sp_id: "149",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.BLUEARROW,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "BlueArrow",
    sp_id: "68",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.CARRIERHQ,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "CarrierHQ",
    sp_id: "69",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ENVUE_TELEMATICS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "EnVue Telematics",
    sp_id: "70",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.FLEET_NAV_SYSTEMS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Fleet Nav Systems",
    sp_id: "79",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.FLEET_PROFIT_CENTER,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Fleet Profit Center",
    sp_id: "77",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.FLEETBOSS_GPS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "FleetBoss GPS (GEOTAB)",
    sp_id: "71",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.FLEETHOSTER,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "FleetHoster",
    sp_id: "55",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.FLEETISTICS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Fleetistics",
    sp_id: "84",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.FLEETLOCATE_GEOTAB,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "FleetLocate (GEOTAB)",
    sp_id: "210",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.FLEETMASTER,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Fleetmaster (GEOTAB)",
    sp_id: "85",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.FLEXPORT,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "Flexport",
  //   sp_id: "151",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.GEOFORCE,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "Geoforce",
  //   sp_id: "106",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.GLOSTONE,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Glostone",
    sp_id: "88",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.GOFLEET,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "GoFleet",
    sp_id: "90",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.GOGPS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "GoGPS",
    sp_id: "212",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.GPS_FLEET_FINDER,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "GPS Fleet Finder",
  //   sp_id: "80",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.GPS_SOLUTIONS,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "GPS Solutions",
  //   sp_id: "102",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.GPS_TRACKING_CANADA,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "GPS Tracking Canada",
    sp_id: "95",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.GRAYBOX_SOLUTIONS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Graybox Solutions",
    sp_id: "94",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.GRIDLINE,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Gridline",
    sp_id: "107",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.HIGHPOINT_GPS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "HighPoint GPS",
    sp_id: "101",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.IOTAB,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "IoTab",
    sp_id: "110",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.LYNX,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "Lynx",
  //   sp_id: "93",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ONTRAK_SOLUTIONS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "OnTrak Solutions",
    sp_id: "99",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.PENSKE,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Penske (GEOTAB)",
    sp_id: "96",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.PREPASS_ELD,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "PrePass ELD",
  //   sp_id: "111",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.QUALITY_GPS,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "Quality GPS",
  //   sp_id: "105",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.RMJ_TECHNOLOGIES,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "RMJ Technologies",
  //   sp_id: "100",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.RUSH_ENTERPRISES,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Rush Enterprises",
    sp_id: "81",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.RYDER,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Ryder",
    sp_id: "211",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.SAFETY_VISION,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Safety Vision (GEOTAB)",
    sp_id: "82",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.SHELL_TELEMATICS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Shell Telematics",
    sp_id: "354",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.SURECAM,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "Surecam",
  //   sp_id: "127",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.T_MOBILE,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "T-Mobile",
    sp_id: "89",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.TRANSFLO,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Transflo",
    sp_id: "54",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.TRAXXISGPS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Traxxis GPS (GEOTAB)",
    sp_id: "73",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.TRUPATH_SYSTEMS,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "TruPath Systems",
    sp_id: "74",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.VERTRAX,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Vertrax",
    sp_id: "91",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ZENDUIT,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Zenduit (GEOTAB)",
    sp_id: "108",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   const: EPlatformName.HOLMAN,
  //   value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
  //   label: "Holman",
  //   sp_id: "152",
  //   methods: [
  //     {
  //       id: "da75b399-451f-401f-a10e-d5bdce6f9259",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://my.geotab.com",
  //       fixed_url: false,
  //       check_connection: true,
  //     },
  //   ],
  // },
  {
    key: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    const: EPlatformName.ENTERPRISE_FLEET_MANAGEMENT,
    value: "c9a688d3-fee4-40c0-b283-c5ec3a75525e",
    label: "Enterprise Fleet Management (GEOTAB)",
    sp_id: "97",
    methods: [
      {
        id: "da75b399-451f-401f-a10e-d5bdce6f9259",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://my.geotab.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "6d83733a-0209-4eea-a2de-1bd8fa201b3c",
    const: EPlatformName.LINXUP,
    value: "6d83733a-0209-4eea-a2de-1bd8fa201b3c",
    label: "Linxup",
    sp_id: "40",
    methods: [
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.linxup.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "6d83733a-0209-4eea-a2de-1bd8fa201b3c",
    const: EPlatformName.FLEETSHARP,
    value: "6d83733a-0209-4eea-a2de-1bd8fa201b3c",
    label: "FleetSharp",
    sp_id: "39",
    methods: [
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.fleetsharp.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "6d83733a-0209-4eea-a2de-1bd8fa201b3c",
    const: EPlatformName.MOTIVE,
    value: "6d83733a-0209-4eea-a2de-1bd8fa201b3c",
    label: "Motive (ex KeepTruckin)",
    sp_id: "3",
    methods: [
      // {
      //   id: "4c6e2f2c-88d8-4beb-9b06-dbbfa23bb7bd",
      //   authorityType: EAuthTypes.OAUTH2,
      //   type: ETelematicsMethodType.PERMANENT_API,
      //   url: "https://api.keeptruckin.com",
      //   fixed_url: true,
      //   check_connection: false,
      // },
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.keeptruckin.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "6d83733a-0209-4eea-a2de-1bd8fa201b3c",
    const: EPlatformName.ONE_STEP_GPS,
    value: "6d83733a-0209-4eea-a2de-1bd8fa201b3c",
    label: "One Step GPS",
    sp_id: "139",
    methods: [
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.onestep.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf16",
    const: EPlatformName.TRIMBLE,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf16",
    label: "Trimble Transportation (Peoplenet)",
    sp_id: "20",
    methods: [
      {
        id: "2294609c-ceec-49b1-87db-438e4687183e",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "http://oi.pfmlogin.com",
        fixed_url: false,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf16",
    const: EPlatformName.WAYLENS,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf16",
    label: "Waylens",
    sp_id: "326",
    methods: [
      {
        id: "2294609c-ceec-49b1-87db-438e4687183e",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "http://waylens.com",
        fixed_url: false,
        check_connection: true,
      },
    ],
  },
  {
    key: "2402e8f8-a751-46e7-93c7-7cb6e13683ea",
    const: EPlatformName.WEBFLEET,
    value: "2402e8f8-a751-46e7-93c7-7cb6e13683ea",
    label: "Webfleet",
    sp_id: "7",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.MANUAL,
        url: "https://csv.webfleet.com",
        fixed_url: false,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.TEMPORARY_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "2402e8f8-a751-46e7-93c7-7cb6e1368dff",
    const: EPlatformName.VERIZON,
    value: "2402e8f8-a751-46e7-93c7-7cdfsdfsdd",
    label: "Verizon Connect Reveal",
    sp_id: "4",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: false,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "2402e8f8-a751-46e7-93c7-7cb6e1368dff",
    const: EPlatformName.GPS_INSIGHT,
    value: "2402e8f8-a751-46e7-93c7-7cdfsdfsdd",
    label: "GPS Insight",
    sp_id: "12",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbf54",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.MANUAL,
        url: "https://gpsinsight.com",
        fixed_url: false,
        check_connection: false,
      },
      // TODO: for 2024 integration C0
      // {
      //   id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
      //   authorityType: EAuthTypes.ACCOUNT,
      //   url: "https://gpsinsight.com",
      //   fixed_url: false,
      //   check_connection: false,
      // },
    ],
  },
  {
    key: "2402e8f8-a751-46e7-93c7-7cb6e1368dff",
    const: EPlatformName.INSIGHT_MOBILE_DATA,
    value: "2402e8f8-a751-46e7-93c7-7cdfsdfsdd",
    label: "Insight Mobile Data",
    sp_id: "131",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f46dee18bbf54",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.MANUAL,
        url: "https://insightmobiledata.com",
        fixed_url: false,
        check_connection: false,
      },
    ],
  },
  {
    key: "2402e8f8-a751-46e7-93c7-7cb6e1368dff",
    const: EPlatformName.MONARCH_GPS,
    value: "2402e8f8-a751-46e7-93c7-7cdfsdfsdd",
    label: "Monarch Tracking",
    sp_id: "142",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f46dee18bbf54",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.MANUAL,
        url: "https://insightmobiledata.com",
        fixed_url: false,
        check_connection: false,
      },
    ],
  },
  {
    key: "2402e8f8-a751-46e7-93c7-7cb6e1368dff",
    const: EPlatformName.RHINO,
    value: "2402e8f8-a751-46e7-93c7-7cdfsdfsdd",
    label: "Rhino",
    sp_id: "359",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f46dee18bbf54",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.MANUAL,
        url: "https://insightmobiledata.com",
        fixed_url: false,
        check_connection: false,
      },
    ],
  },
  {
    key: "2402e8f8-a751-46e7-93c7-7cb6e1368dff",
    const: EPlatformName.TOUCHTEL_GPS,
    value: "2402e8f8-a751-46e7-93c7-7cdfsdfsdd",
    label: "TouchTel GPS",
    sp_id: "156",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f46dee18bbf54",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.MANUAL,
        url: "https://insightmobiledata.com",
        fixed_url: false,
        check_connection: false,
      },
    ],
  },
  // {
  //   key: "2402e8f8-a751-46e7-93c7-7cb6e1368dff",
  //   const: EPlatformName.TRUCKSPY,
  //   value: "2402e8f8-a751-46e7-93c7-7cdfsdfsdd",
  //   label: "Truckspy",
  //   sp_id: "222",
  //   methods: [
  //     {
  //       id: "76d3ab91-549c-404c-a174-f46dee18bbf54",
  //       authorityType: EAuthTypes.TOKEN,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://insightmobiledata.com",
  //       fixed_url: false,
  //       check_connection: false,
  //     },
  //   ],
  // },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.MIX_TELEMATICS,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Mix Telematics",
    sp_id: "9",
    methods: [
      {
        id: "2294609c-ceec-49b1-87db-438e46656455",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://mix-telematics.com",
        fixed_url: true,
        check_connection: true,
      },
    ],
  },
  {
    key: "5cf5fc28-d12c-4248-afb5-0bd7526cb203",
    const: EPlatformName.EROAD,
    value: "5cf5fc28-d12c-4248-afb5-0bd7526cb203",
    label: "EROAD",
    sp_id: "28",
    methods: [
      {
        id: "4c6e2f2c-88d8-4beb-9b06-dbbfa23bb7bd",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.eroad.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.FLEET_COMPLETE,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Fleet Complete",
    sp_id: "8",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.MANUAL,
        url: "https://fleetcomplete.com",
        fixed_url: false,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.ATNT_FLEET_COMPLETE,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "AT&T (Fleet Complete)",
    sp_id: "53",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.MANUAL,
        url: "https://attfleetcomplete.com",
        fixed_url: false,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.FLEET_UP,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "FleetUp",
    sp_id: "366",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.MANUAL,
        url: "https://fleetup.com",
        fixed_url: false,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  // {
  //   key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   const: EPlatformName.GPS_TAB,
  //   value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   label: "GPS Tab",
  //   sp_id: "123",
  //   methods: [
  //     {
  //       id: "937ed023-4642-4582-bb21-43b680ef5967",
  //       authorityType: EAuthTypes.TOKEN,
  //       type: ETelematicsMethodType.PERMANENT_API,
  //       url: "https://api.rr.com",
  //       fixed_url: true,
  //       check_connection: true,
  //     },
  //     {
  //       id: "id",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://someurl.com",
  //       fixed_url: true,
  //       check_connection: false,
  //     },
  //   ],
  // },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.LION_EIGHT,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "LionEight",
    sp_id: "224",
    methods: [
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.lioneight.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.ELD_RIDER_PRO,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "ELD Rider Pro",
    sp_id: "214",
    methods: [
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.eldriderpro.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.SHELD,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "SHELD",
    sp_id: "215",
    methods: [
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.sheld.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.XELD,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "XELD",
    sp_id: "120",
    methods: [
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.xeld.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.ZELD,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "ZELD",
    sp_id: "140",
    methods: [
      {
        id: "937ed023-4642-4582-bb21-43b680ef5967",
        authorityType: EAuthTypes.TOKEN,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://api.zeld.com",
        fixed_url: true,
        check_connection: true,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.LYTX,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Lytx",
    sp_id: "5",
    methods: [
      // {
      //   id: "4c6e2f2c-88d8-4beb-9b06-dbbf3bb7bd",
      //   authorityType: EAuthTypes.TOKEN,
      //   type: ETelematicsMethodType.PERMANENT_API,
      //   url: "https://api.lytx.com",
      //   fixed_url: true,
      //   check_connection: true,
      // },
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        type: ETelematicsMethodType.MANUAL,
        authorityType: EAuthTypes.ACCOUNT,
        url: "https://lytx.com",
        fixed_url: false,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.OMNITRACS,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Omnitracs ES",
    sp_id: "18",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        type: ETelematicsMethodType.MANUAL,
        authorityType: EAuthTypes.ACCOUNT,
        url: "https://omni.com",
        fixed_url: false,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.ISAAC,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Isaac Instruments",
    sp_id: "205",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.AZUGA,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Azuga Fleet",
    sp_id: "300",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "0b244751-110c-4f96-884e-8aebe1ad2588",
    const: EPlatformName.AZUGA_ELD,
    value: "af7afc80-19fa-495f-ac33-91ca075bb7c4",
    label: "Azuga ELD (fka Vistracks)",
    sp_id: "356",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: true,
      },
    ],
  },
  // {
  //   key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   const: EPlatformName.WORKWAVE,
  //   value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   label: "Workwave (GPS heroes)",
  //   sp_id: "420",
  //   methods: [
  //     {
  //       id: "id",
  //       authorityType: EAuthTypes.ACCOUNT,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://someurl.com",
  //       fixed_url: true,
  //       check_connection: false,
  //     },
  //   ],
  // },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.NETRADYNE,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Netradyne",
    sp_id: "34",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  // {
  //   key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   const: EPlatformName.NEXTRAQ,
  //   value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   label: "Nextraq",
  //   sp_id: "17",
  //   methods: [
  //     {
  //       id: "id",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://someurl.com",
  //       fixed_url: true,
  //       check_connection: false,
  //     },
  //   ],
  // },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.FLEETLOCATE_SPIREON_NSPIRE,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Fleet Locate 2.1 (Spireon NSpire)",
    sp_id: "52",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://spireon.com",
        fixed_url: false,
        check_connection: false,
      },
    ],
  },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.XRS,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "XRS",
    sp_id: "43",
    methods: [
      {
        id: "76d3ab91-549c-404c-a174-f6dee18bbfca",
        type: ETelematicsMethodType.MANUAL,
        authorityType: EAuthTypes.ACCOUNT,
        url: "https://xrs.com",
        fixed_url: false,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "859031d1-74a5-46a1-8eff-d0758e456c66",
    const: EPlatformName.ZONAR,
    value: "b60501e4-3624-4857-9d34-97b02e5eb4f2",
    label: "Zonar",
    sp_id: "31",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  // {
  //   key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   const: EPlatformName.ORBCOMM_ELD,
  //   value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   label: "Orbcomm (ELD)",
  //   sp_id: "19",
  //   methods: [
  //     {
  //       id: "id",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://someurl.com",
  //       fixed_url: true,
  //       check_connection: false,
  //     },
  //   ],
  // },
  // {
  //   key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   const: EPlatformName.ORBCOMM_BLUE_TREE,
  //   value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   label: "Orbcomm (BlueTree)",
  //   sp_id: "19",
  //   methods: [
  //     {
  //       id: "id",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://someurl.com",
  //       fixed_url: true,
  //       check_connection: false,
  //     },
  //   ],
  // },
  // {
  //   key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   const: EPlatformName.VISION_TRACK,
  //   value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
  //   label: "Vision Track",
  //   sp_id: "226",
  //   methods: [
  //     {
  //       id: "id",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://someurl.com",
  //       fixed_url: true,
  //       check_connection: false,
  //     },
  //   ],
  // },
  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    const: EPlatformName.GORILLA_SAFETY,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf76",
    label: "Gorilla Safety",
    sp_id: "44",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "dbcf084b-b59f-47d6-ac7c-03c8eef8e5496",
    const: EPlatformName.CLUTCH_ELD,
    value: "dbcf084b-b59f-47d6-ac7c-03c8eef8e549",
    label: "Clutch ELD",
    sp_id: "113",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "0a2c3c65-e459-4d29-a0d0-751d37165375",
    const: EPlatformName.CYNTRX_ELD_PLUS,
    value: "0a2c3c65-e459-4d29-a0d0-751d37165375",
    label: "CyntrX ELD",
    sp_id: "114",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "99622150-1a01-47ae-9327-6cb34e71b06a",
    const: EPlatformName.ELD_MANDATE_PRO,
    value: "99622150-1a01-47ae-9327-6cb34e71b06a",
    label: "ELD Mandate Pro",
    sp_id: "118",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  // {
  //   key: "b06f1a35-ac1e-467b-bf63-6bd9e9550e84",
  //   const: EPlatformName.FLEET_LOCATE_ELD,
  //   value: "b06f1a35-ac1e-467b-bf63-6bd9e9550e84",
  //   label: "FleetLocate ELD",
  //   sp_id: "146",
  //   methods: [
  //     {
  //       id: "id",
  //       authorityType: EAuthTypes.TOKEN,
  //       type: ETelematicsMethodType.PERMANENT_API,
  //       url: "https://someurl.com",
  //       fixed_url: true,
  //       check_connection: false,
  //     },
  //     {
  //       id: "id",
  //       authorityType: EAuthTypes.BASIC,
  //       type: ETelematicsMethodType.MANUAL,
  //       url: "https://someurl.com",
  //       fixed_url: true,
  //       check_connection: false,
  //     },
  //   ],
  // },
  {
    key: "3f0872d8-f1f4-4110-bc75-4078117e071c",
    const: EPlatformName.PATRIOT,
    value: "3f0872d8-f1f4-4110-bc75-4078117e071c",
    label: "Patriot ELD",
    sp_id: "358",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "862cdf05-4b4b-463e-9a23-b75ca0299d1c",
    const: EPlatformName.SIMPLEX_ELD,
    value: "862cdf05-4b4b-463e-9a23-b75ca0299d1c",
    label: "Simplex ELD",
    sp_id: "213",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "3db28eb8-a326-4cde-ac76-bfe2db719147",
    const: EPlatformName.TRACK_ON_ELD,
    value: "3db28eb8-a326-4cde-ac76-bfe2db719147",
    label: "TrackOn ELD",
    sp_id: "144",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "2da21978-55ac-4dd9-a5a7-225be988295c",
    const: EPlatformName.TRUCKER_PATH_ELD_PRO,
    value: "2da21978-55ac-4dd9-a5a7-225be988295c",
    label: "Trucker Path ELD Pro",
    sp_id: "134",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.PERMANENT_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
      {
        id: "id",
        authorityType: EAuthTypes.BASIC,
        type: ETelematicsMethodType.MANUAL,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
  {
    key: "536d249b-78d7-4e2a-a5d9-06d530027f10",
    const: EPlatformName.TELETRAC_NAVMAN,
    value: "536d249b-78d7-4e2a-a5d9-06d530027f10",
    label: "Teletrac Navman",
    sp_id: "135",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.ACCOUNT,
        type: ETelematicsMethodType.MANUAL,
        url: "https://csv.webfleet.com",
        fixed_url: false,
        check_connection: false,
      },
    ],
  },

  {
    key: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf77",
    const: EPlatformName.REQUEST,
    value: "d45d1ef6-bc0d-4ff9-9874-bedd6a9ecf77",
    label: "Missing your TSP?",
    sp_id: "136",
    methods: [
      {
        id: "id",
        authorityType: EAuthTypes.REQUEST,
        type: ETelematicsMethodType.REQUEST_API,
        url: "https://someurl.com",
        fixed_url: true,
        check_connection: false,
      },
    ],
  },
];
